import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import Cover from '../../assets/jelenamaric.jpg';
import PlayButton from '../../assets/playbutton.png';
import Bg from '../../assets/footer-topbg.png';
import Figure1 from '../../assets/subf1.png';
import Figure2 from '../../assets/subf2.png';
import MenuClose from '../../assets/menuclose.png'




const Jelena = () => {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false)


  useEffect(() => {
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[sidebarOpen])
  return (
    <div>
      <div className="header-hero position-relative d-flex flex-column">
        <NavBar  />
        <Sidebar  />
        <div className="mx-auto container position-relative picture-info my-auto" onClick={()=> setOpen(true)}>
          <img className='cover-subpage d-block mx-auto' style={{maxWidth:'100%'}} src={Cover} alt="" />
          <img className='position-absolute play-button' src={PlayButton} alt="" />
        </div>
      </div>
      <div className="subpage-info" style={{backgroundImage:`url(${Bg})`}}>
        <div className="container">
          <div className="subpage-box py-5">
            <h1 className='text-center mb-5'>Od magacionera do preduzetnika</h1>
            <p>
              Šta se nalazi nasuprot aplikacijama za akcijsku kupovinu? Aplikacija za prodaju polovne garderobe! Jelena Marić, mlada preduzetnica smislila je način kako da olakša prepune plakare, a da vlasnici garderobe tom prilikom još i zarade. <br /> <br />
HES (Hot Express Sale) je aplikacija za prodaju polovne (ili nove) garderobe ili aksesoara, koja je za četrnaest meseci postojanja uspela da privuče petnaest hiljada korisnika i pet i po miliona poseta! U toj interakciji, tokom ovog kratkog perioda prodato je oko četrdeset hiljada artikala! Neverovatno je da za pokretanje biznisa nije bilo potrebno skoro ništa, odnosno tačno dvesta eura, to jest, petsto, ukoliko se uračunaju i troškovi digitalnog marketinga. <br /> <br />
 Jelena Marić, ekonomista je po struci, ali takođe i neko ko dolazi iz porodice sa dužim preduzetničkim stažom. Počela je upravo u porodičnoj firmi, kao magacioner, prodavac, da bi kasnije stigla i do menadžera prodaje. Iako moda i laminatni podovi, što je delatnost porodične firme, nemaju mnogo toga zajedničkog, manir i metodologija rada izgleda da se ne razlikuju previše. Želeći da izađe iz biznis senke svojih roditelja, započela je realizaciju sopstvene ideje. Do nje je kaže došla tako što je istraživala šta to sve fali ovom tržištu. Mama dvoje dece hrabro je iskoračila u ovaj projekat u trenutku kada joj je drugo dete imalo samo četiri meseca. <br /> <br /> Zato i smatra da deca ženama ne treba da budu ograničavajući faktor u karijeri, već naprotiv, motivacija. Ono što joj takođe nije dozvolilo da odustane, čak ni onda kada bi stvari postale najizazovnije, a to je must svakog preduzetništva, upravo je pozitivan fidbek od klijenata. Broj korisnika povećava se brže nego što je Jelena planirala, a faktor koji HES aplikaciju izdvaja od drugih upravo je to što zadata cena ponuđača komada koji se prodaje ostaje nepromenjena, nema provizije, odnosno celokupan iznos odlazi prodavcu onog trenutka kada se se odevni predmet proda. Ići u korak s vremenom i pratiti situaciju na terenu, takođe, su od ključnog značaja, zato je je Jelena pri pokretanju biznisa, jasno imala na umu zvanični podatak koji se odnosi na onlajn prodaju – a to je rast za četristo odsto od čega se polovina tiče upravo prodaje garderobe! Iako nije planirala ovakav uspeh za kratko vreme, Jelena uveliko pravi nove planove koji se pre svega odnose na što bolje i jednostavnije komuniciranje sa klijentima. Krajnji cilj je internacionalizacija projekta, prve ponude se već naziru i kako kaže, bila bi luda da to ne iskoristi.
            </p>
            <img className='position-absolute figone' src={Figure1} alt="" />
            <img className='position-absolute figtwo' src={Figure2} alt="" />
          </div>
        </div>
      </div>
      {
        isOpen &&
        <div className="video-modal">
           <iframe width="100%" height="100%" src="https://www.youtube.com/embed/GWzPyVfByGA?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}

export default Jelena