import React from 'react';
import Astronautfooter2 from '../assets/astronautfooter2.png';
import Astronautfooter1 from '../assets/astronautfooter1.png';
import Footerfigure1 from '../assets/footer-figure1.png';
import Ministarstvo from '../assets/ministarstvo.png';
import Footerfigure2 from '../assets/footer-figure2.png';
import Footerkrug from '../assets/footer-krug.png';
import Instagram2 from '../assets/instagram2.png';
import Instagram1 from '../assets/instagram1.png';
import Footerlinija from '../assets/footerlinija.png';
import Footerplaneta from '../assets/footerplaneta.png';
import Footerbg from '../assets/footerbg.png';
import Footertopbg from '../assets/footer-topbg.png';
import Footerlogobig from '../assets/footer-logo-big.png';
import Facebook from '../assets/facebook.png';
import Instagram from '../assets/instagram.png';
import Youtube from '../assets/youtube.png'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-top d-flex flex-column" style={{background:`url(${Footertopbg})`}}>
                <div className='mx-auto planeta planeta-animate'>
                    <img src={Footerplaneta} alt="" />
                </div>
                <div className='mx-auto'>
                    <img src={Astronautfooter1} alt="" />
                </div>
                <div className='footer-red d-flex flex-column mx-auto position-relative'>
                    <div className='footer-curved-line position-absolute'>
                        <img src={Footerlinija} alt="" />
                    </div>
                    <div className="footer-text footer-padding">
                        <p>
                            Реализацијом овог програма Република Србија настоји да подстакне грађане да размишљају на предузетнички начин, као и да их охрабри да у већој мери оснивају своје иновационе и технолошке стартап компаније.
                        </p>
                    </div>
                    <div className='astrounaut-footer mx-auto position-relative'>
                        <img src={Astronautfooter2} alt="" />
                    </div>
                    <div className="footer-two">
                        <div className='one'>
                            <h2>ИНОВАЦИЈЕ <br /> СВЕТ ОКО <br/> НАС</h2>
                        </div>
                        <div className="two">
                            <a href="https://www.instagram.com/inovacijesvetokonas/" target="_blank">
                            <div className="d-flex align-items-center">
                                <img src={Instagram1} alt="" />
                                <img src={Instagram2} alt="" />
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom d-flex flex-column" style={{background:`url(${Footerbg})`}}>
                <div className="top-extend mx-auto d-flex position-relative">
                    <div className="line-center"></div>
                    <div className="footer-bottom-logo d-flex justify-content-center align-items-center">
                        <img src={Footerkrug} alt="" />
                    </div>
                </div>
                <div className='bottom-footer-logo d-none d-lg-flex flex-column'>
                    <img src={Footerlogobig} alt="" />
                </div>
                <div className="footer-figure1 position-absolute">
                    <img src={Footerfigure2} alt="" />
                </div>
                <div className="footer-figure2 position-absolute">
                    <img src={Footerfigure1} alt="" />
                </div>
                <div className="ministarstvo position-absolute">
                    <a href="https://inovacije.gov.rs/" target="_blank"><img src={Ministarstvo} alt="" /></a>
                </div>
                <div className="social-footer d-flex">
                    <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
                        <a href="https://www.facebook.com/InovacijeSvetOkoNas/" target="_blank"><img src={Facebook} alt="" /></a>
                        <div className="line-social"></div>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
                        <a href="https://www.instagram.com/inovacijesvetokonas/" target="_blank"><img src={Instagram} alt="" /></a>
                        <div className="line-social"></div>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
                        <a href="https://www.youtube.com/channel/UC4abbBoDN5PSWjOrmGeLahw" target="_blank"><img src={Youtube} alt="" /></a>
                        <div className="line-social"></div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default Footer;