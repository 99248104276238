import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import Cover from '../../assets/urosdjakovic.jpg';
import PlayButton from '../../assets/playbutton.png';
import Bg from '../../assets/footer-topbg.png';
import Figure1 from '../../assets/subf1.png';
import Figure2 from '../../assets/subf2.png';
import MenuClose from '../../assets/menuclose.png'




const Uros = () => {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false)


  useEffect(() => {
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[sidebarOpen])
  return (
    <div>
      <div className="header-hero position-relative d-flex flex-column">
        <NavBar  />
        <Sidebar  />
        <div className="mx-auto container position-relative picture-info my-auto" onClick={()=> setOpen(true)}>
          <img className='cover-subpage d-block mx-auto' style={{maxWidth:'100%'}} src={Cover} alt="" />
          <img className='position-absolute play-button' src={PlayButton} alt="" />
        </div>
      </div>
      <div className="subpage-info" style={{backgroundImage:`url(${Bg})`}}>
        <div className="container">
          <div className="subpage-box py-5">
            <h1 className='text-center mb-5'>Упорност и знање се увек исплате</h1>
            <p>
              Урош Ђаковић  је оснивач стартапа Беохост и регионални директор Реалпада за Балкан. Примарни задатак његовог пословања је дигитализација свих процеса у сектору некретнина, кроз софтверска решења која у великој мери олакшавају рад инвеститорима, агентима продаје и купцима у овој индустрији. <br/> <br />
Још као дете, Урош је знао да је створен за бизнис. Како каже није предуго чекао да почне са реализацијом визије, тако је са екипом другара, још док је време студирао кренуо почео да ради. Предузетништво му је у крв, бизнис којим се бави је право остварење сна. <br/> <br />
Све је почело још на факултету када је са другарима кренуо са услугама цо-хоста, то јест ефикаснијег коришћења продајним каналима као што су Боокинг и Аирбнб као и самог одржавања апартмана. Убрзо је схватио да за таквим видом пословања, постоји велика потражња. Чим су зарадили први већи новац, од вршњака је уследило „логично“ питање – „који ауто планирате да пазарите?“ Иако такво размишљање одговара узрасту, Урош је пред собом имао другачију замисао. Од самог старта размишљао је како да зарађеним новцем из посла унапреди посао, да имплементира ново решење и да му то донесе још новца и нових корисника. <br/> <br />
 Предузетништво је од самог старта схватио каоједну врсту игре. Идеја је да кроз нове услуге треба понудити напреднија решења која ће помоћи другима, и то сматра примарним изазовом. Такав начин размишљања је подстакао да оговоре на питања о иновацијама потражи у иностранству од оних који у томе имају дужи, потврђени стаж. То је и реализовао. Преузео је те патерне, и потом их прилагодио захтевима домаћег тржишта. Истим обрасцем води се и данас када је у пословању одмакао далеко остајући ипак веран првој „теми“. Корак по корак, стигао је до статистике која каже да се годишње кроз њихов систем прода око десет хиљада станова. Управо таква повртана спрега,  говори им да су уболи у срж  и да иду добрим путем. Својим софтверским решењима успели су да дигитализују сваки процес који је неопходан да се заврши у једном сегменту пословања у реал естате сектору. <br/> <br />
Тешки тренуци су неминовност ове „игре“, а оно што омогућава да се проблеми превазиђу, јесте добар и здрав тим. Као добар пример издваја прекретни моменат, када им је за реализацију зацртаног циља била неопходна сасвим извесна инвестиција, коју на крају ипак нису добили. Иако није видео решење како ће се даље развијати, управо тим је био тај, који га је како каже, одржао на површини. Економиста је по струци, али то ипак није било довољно за оно што га је чекало у послу. Неке полазне основе са факултета помогле су у старту, али убрзо је бизнис надвладао знање. Путем интернета едуковао се вештинама неопходним за даљи развој: пласирање  производа, оглашавање... Докле га је глад за знањем довела најбоље објашњава одговор на питање где своју фирму види у наредних пет година, на шта он спремно одговара: „У европској лиги, и то искључиво у лидерском руху!“

            </p>
            <img className='position-absolute figone' src={Figure1} alt="" />
            <img className='position-absolute figtwo' src={Figure2} alt="" />
          </div>
        </div>
      </div>
      {
        isOpen &&
        <div className="video-modal">
           <iframe width="100%" height="100%" src="https://www.youtube.com/embed/GWzPyVfByGA?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}

export default Uros