
import React, { Component } from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/HomePage/HomePageTemplate';
import './App.scss';
import {Route, Switch} from 'react-router-dom';
import "bootstrap/scss/bootstrap.scss";
import 'react-modal-video/scss/modal-video.scss';
import Aleksandra from "./pages/subpages/Aleksandra";
import Eleonora from "./pages/subpages/Eleonora";
import Jelena from "./pages/subpages/Jelena";
import Lena from "./pages/subpages/Lena";
import Nenad from "./pages/subpages/Nenad";
import Uros from "./pages/subpages/Uros";




class App extends Component {
  render() {
    return <>

      <Switch>
        <Route  path="/" exact component={Home} />
        <Route  path="/aleksandra"  component={Aleksandra} />
        <Route  path="/eleonora"  component={Eleonora} />
        <Route  path="/jelena"  component={Jelena} />
        <Route  path="/lena"  component={Lena} />
        <Route  path="/nenad"  component={Nenad} />
        <Route  path="/uros"  component={Uros} />
      </Switch>
      <Footer />
    </>
  }
}


   export default App;
