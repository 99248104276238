import React from 'react';
import Sekcija2glavni from '../assets/sekcija2glavni.png';
import Sekcija2sekundarni from '../assets//sekcija2sekundarni.png';
import Sekcija2pisma from '../assets//sekcija2pisma.png';
import Sekcija2velikikrug from '../assets/sekcija2velikikrug.png';
import Astrounaut2 from '../assets//astronaut2.png';
import Sekcija2malikrug from '../assets/sekcija2malikrug.png';
import Sekcija2figura from '../assets/sekcija2figura.png'

export default function About() {
  return (
    <div id="about" className='section2 d-flex flex-column position-relative'>
      <div className='section2__pisma'>
        <img src={Sekcija2pisma} alt="" />
      </div>
      <div className="astronaut2">
        <img src={Astrounaut2} alt="" />
      </div>
        <div className="section2__line mx-auto"></div>
        <div className="section2__container mx-auto position-relative d-flex">
          <img className='section2__mainbg' src={Sekcija2glavni} alt="" />
          <img className='section2__coverbg' src={Sekcija2sekundarni} alt="" />
          <div className='section2__text'>
            <div className='text-center mx-auto'>
              <a className='color-red' href="https://inovacijesvetokonas.rs/2021">2020 / 2021</a>
            </div>
            <p>Програм <span style={{color:'#FD5353'}}>ИНОВАЦИЈЕ СВЕТ ОКО НАС</span> настао је са циљем да информише јавност, универзитетске, академске, научно-истраживачке и пословне заједнице, а пре свега младе, о системским мерама које Влада спроводи у циљу развоја технолошких и друштвених иновација.</p>
            <p>Реализацијом овог програма Република Србија настоји да подстакне грађане да размишљају на предузетнички начин, као и да их охрабри да у већој мери оснивају своје иновационе и технолошке стартап компаније.</p>
          </div>
          <div className="section2__bigcircle">
            <img src={Sekcija2velikikrug} alt="" />
          </div>
          <div className="section2__malikrug">
            <img src={Sekcija2malikrug} alt="" />
          </div>
          <div className="section2__figura">
            <img src={Sekcija2figura} alt="" />
          </div>
        </div>
    </div>
  )
}
