import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import Cover from '../../assets/eleonorasergijevic.jpg';
import PlayButton from '../../assets/playbutton.png';
import Bg from '../../assets/footer-topbg.png';
import Figure1 from '../../assets/subf1.png';
import Figure2 from '../../assets/subf2.png';
import MenuClose from '../../assets/menuclose.png'




const Eleonora = () => {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false)


  useEffect(() => {
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[sidebarOpen])
  return (
    <div>
      <div className="header-hero position-relative d-flex flex-column">
        <NavBar  />
        <Sidebar  />
        <div className="mx-auto container position-relative picture-info my-auto" onClick={()=> setOpen(true)}>
          <img className='cover-subpage d-block mx-auto' style={{maxWidth:'100%'}} src={Cover} alt="" />
          <img className='position-absolute play-button' src={PlayButton} alt="" />
        </div>
      </div>
      <div className="subpage-info" style={{backgroundImage:`url(${Bg})`}}>
        <div className="container">
          <div className="subpage-box py-5">
            <h1 className='text-center mb-5'>Приђи ближе да те боље видим!</h1>
            <p>
              МојоAI је стартап који се бави таргетираним рекламирањем и квантитативним истраживањем тржишта у реалном времену на самом месту продаје, помажући брендовима, маркетиншким агенцијама и власницима продавница да боље упознају потенцијалне муштерије које се налазе на конкретној локацији, а све у циљу да их конвертују у купце. <br/> <br />
Елеонора Сергијевић индустријски дизајнер по струци, директор је и сувласник стартапа Мојо AI, формално насталог прошле године. Тачно у време када су брендови и продавнице са физичким локацијама почели да увиђају неке од недостатака класичног дигиталног маркетинга, тачније недостатак везе између онлине маркетинга и маркетинга на самом месту продаје. <br/> <br />
МојоАI уводи све предности дигиталног маркетинга, као што су таргетирање и аналитика у реалном времену на само место продаје, на саме локације, пружајући могућност својим клијентима да упознају своје посетиоце, тренутно присутне на локацији, да их лакше конвертују у купце, али и да доносе одлуке засноване на подацима. <br/> <br />
Мојо је заснован на вештачкој интелигенцији која посматра и анализира посетиоца на самој локацији и спроводи аналитику у реалном времену. Не само да се тако стиче прецизан увид у то када су одређене циљне групе биле на месту продаје у које време,  већ дају тачну информацију како су посетиоци који су видели одређену рекламу на њу реаговали. Јер, иза речи посетиоци, крију се људи, који позитивно или негативно реагују на неки рекламни садржај. <br/> <br />
Зато је управо реакција посетилаца та која даје кључне смернице како ће се и на кога даља маркетиншка стратегија усмеравати. Захваљујући оваквом увиду, брендови имају могућност да се обраћају својој публици на врло директан, личан начин, прилагођен свакој групи појединачно. <br/> <br />
Елеонору је до овог стартапа  постепено довело предузетништво у коме је већ тринаест година. Након каријере индустријског дизајнера, нашла се на позицији креативног директора једне од најбољих продукцијских БТЛ компанија на Балкану, где је из прве руке добила информације о потребама тржишта и недостацима постојећих приступа и решења, што је и изнедрило идеју о стартапу. Данас су отишли и корак даље, формирали су и сопствени производ, у чему су их подржали Стартецх, финансиран од стране Филип Мориса, као и Фонд за Иновационе делатности Републике Србије. <br/> <br />
Када је у питању интерно и екстерно комуницирање стартапа, унутура и изван њега, Елеонора напомиње да код нас тренутно не постоји адекватна школа, односно едукација, која је у стању да у потпуности припреми појединца за иновативно предузетништво. Напомиње да су најкорисније приче оне које се успут размењују са људима из других стартапа. Искуство и сугестија попут „Ја сам ово урадио овако и зезнуо, немој то никако“, највредније је, а тренутно и једино усмерење које се може добити, тако да саветује свим оснивачима да се повежу са другима у најранијој фази и отворено причају о својим, како добрим, тако и лошим искуствима. <br/> <br />
Када су у питању планови, Елеонора напомиње да их је у динамичном, високо технолошком стартапу, као што је МојоАI незахвално коментарисати, јер да јој је неко пре пет година рекао да ће данас бити овде, одговорила би да технологија за то не постоји, јер у том тренутку и није постојала. <br/> <br />
Данас, као један од будућих таргета, издваја могућност да се подаци о самом месту продаје и навикама потрошача који се добијају помоћу МојоАI уређаја и аналитике употребе за боље планирање снабдевености, као и за смањење отпада насталог у продајним ланцима у будућности.

            </p>
            <img className='position-absolute figone' src={Figure1} alt="" />
            <img className='position-absolute figtwo' src={Figure2} alt="" />
          </div>
        </div>
      </div>
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/PRi3iaXAm90?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}

export default Eleonora