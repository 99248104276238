import React,{useState, useEffect} from 'react'
import Sectionbgwrap from '../assets/sectionbgwrap.png';
import Sekcija6cover from '../assets//sekcija6bgcover.png';
import Sekcija6figura1 from '../assets/sekcija6figura1.png';
import Sekcija6figura2 from '../assets/sekcija6figura2.png';
import Slika2 from '../assets/lena.png';
import MenuClose from '../assets/menuclose.png';




export default function Lena() {

  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])

  return (
    <div className='section6 position-relative'>
      <div className="d-flex flex-column flex-row flex-lg-row-reverse section-universal mx-auto align-items-center">
        <div id="lena" className="sectionslikaright vibrate-1" onClick={()=> setOpen(true)}>
          <img src={Slika2} alt="" />
        </div>
        <div className="sectioncontent">
          <img className='section-wrapbg' src={Sectionbgwrap} alt="" />
          <img className='section-coverbg-6' src={Sekcija6cover} alt="" />
          <p className="section-text">
            <span className='color-red'>Лена Миладиновић </span> је српска шахисткиња, женски интернационални мајстор, шаховски тренер и судија. Недавно је своју биографију обогатила и једном ванспортском активношћу, и то потпуно спонтано, постала је предузетница. <a className='color-red'  href="/lena">Прочитај више</a>
          </p>
          <img className='section6-figure1' src={Sekcija6figura1} alt="" />
          <img className='section6-figure2' src={Sekcija6figura2} alt="" />
          {/* <img className='section-3-malikrug' src={Sekcija3malikrug} alt="" /> */}
        </div>
      </div>
      {/* <img className='section-3-velikikrug' src={Sekcija3velikikrug} alt="" /> */}
      {
        isOpen &&
        <div className="video-modal">
           <iframe width="100%" height="100%" src="https://www.youtube.com/embed/GWzPyVfByGA?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
