import React, {useState, useContext, useEffect} from 'react';
import NavBar from './NavBar';
import InovacijeTekst from '../assets/inovacijetekst.png';
import Astronaut from '../assets/astronaut.png';
import backgroundText from '../assets/tekstbg.png';
import ButtonLogo from '../assets/buttonLogo .png';
import Facebook from '../assets/facebook.png';
import Instagram from '../assets/instagram.png';
import Youtube from '../assets/youtube.png';
import Mouse from '../assets/mouse.png';
import Sidebar from './Sidebar';
import MenuClose from '../assets/menuclose.png';

import {ProductContext} from '../context/context';

export default function Header() {

  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);



  useEffect(() => {
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[sidebarOpen])

  return (
    <div className="header-hero position-relative">
      <NavBar  />
      <Sidebar  />
      <img className='d-block d-md-none ml-auto atromobile' src={Astronaut} alt="" />
      <div className='content-hero'>
        <div className='d-flex'>
          <img className='inovacije-tekst d-none d-md-block' src={InovacijeTekst} alt="" />
          <h1 className='d-block d-md-none text-center text-uppercase' style={{zIndex:2, fontWeight:"bold", marginTop:'40px'}}>Иновацје <br /> свет око нас</h1>
        </div>
        <div className="line my-2" style={{zIndex:3}}></div>
        <div className="hero-container mb-3" style={{zIndex:12}}>
          <p>
            Програм <br  className='d-block d-md-none'/> <span style={{backgroundImage:`url(${backgroundText})`}}>ИНОВАЦИЈЕ СВЕТ ОКО НАС</span> настао је са циљем да информише
          јавност о системским мерама које Влада спроводи у циљу
          развоја технолошкихи друштвених иновација. <button onClick={()=> setOpen(true)} style={{background: '#FD5353', fontWeight:'black', padding:'10px, 5px', borderRadius:'10px', marginLeft:'10px', marginTop:'10px', color:'white', border:'none'  }}>ПОГЛЕДАЈ ВИДЕО</button>
          </p>
          <div class="astronaut">
            <img src={Astronaut} alt="" />
          </div>
        </div>
        <button className='hero-button d-flex align-items-center'><a className='color-white' href="#about">ПРОЧИТАЈ ВИШЕ</a></button>
        <div className="social-hero d-flex">
          <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
            <a href="https://www.facebook.com/InovacijeSvetOkoNas/" target="_blank"><img src={Facebook} alt="" /></a>
            <div className="line-social"></div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
            <a href="https://www.instagram.com/inovacijesvetokonas/" target="_blank"><img src={Instagram} alt="" /></a>
            <div className="line-social"></div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
            <a href="https://www.youtube.com/channel/UC4abbBoDN5PSWjOrmGeLahw" target="_blank"><img src={Youtube} alt="" /></a>
            <div className="line-social"></div>
          </div>
        </div>
        <div className="scroll-icon">
          <div className='position-relative'>
            <div class="scroll-icon-wrap ping d-none d-md-flex justify-content-center align-items-center"></div>
            <a href="#about"><img src={Mouse} alt="" /></a>
          </div>
        </div>
      </div>
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/hkwqklg_hYk?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
