import React, {useContext} from 'react';
import Logo from '../assets/logo.png'
import {ProductContext} from '../context/context';
import MenuOpen from '../assets/menuopen.png';


const NavBar = (props) => {

    const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;

    return (
        <div className='d-flex justify-content-between'>
            <div className="logo">
                <a href="https://inovacijesvetokonas.rs/">
                    <img src={Logo} alt="" />
                </a>
            </div>
            <div style={{zIndex:2, cursor:'pointer'}} onClick={() => handleSidebar()} className="main-menu">
                <img src={MenuOpen} alt="" />
            </div>
        </div>
     );
}




export default NavBar;