import React,{useState, useEffect} from 'react';
import Sectionbgwrap from '../assets/sectionbgwrap.png';
import Sekcija8cover from '../assets//sekcija8bgcover.png';
import Sekcija8figura1 from '../assets/sekcija8figura1.png';
import Sekcija8figura2 from '../assets/sekcija8figura2.png';
import Slika2 from '../assets/uros.png';
import Sekcija8velikikrug from '../assets/sekcija8velikikrug.png';
import MenuClose from '../assets/menuclose.png';


export default function Mihajlo() {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])

  return (
    <div className='section8 position-relative'>
      <div className="d-flex flex-column flex-row flex-lg-row-reverse section-universal mx-auto align-items-center">
        <div id="nenad" className="sectionslikaright vibrate-1" onClick={()=> setOpen(true)}>
          <img src={Slika2} alt="" />
        </div>
        <div className="sectioncontent">
          <img className='section-wrapbg' src={Sectionbgwrap} alt="" />
          <img className='section-coverbg-8' src={Sekcija8cover} alt="" />
          <p className="section-text">
            <span className='color-red'>Ненад Благојевић </span> власник је и оснивач портала „Приче са душом“, занимљивих прича предузетника и талентованих људи. Приче са душом заиста не беже много од свог имена – „Сестре започеле бизнис у селу у коме је остала једна кућа“... <a className='color-red' href="/nenad">Прочитај више</a>
          </p>
          <img className='section8-figure1' src={Sekcija8figura2} alt="" />
          <img className='section8-figure2' src={Sekcija8figura1} alt="" />
          {/* <img className='section-3-malikrug' src={Sekcija3malikrug} alt="" /> */}
        </div>
      </div>
      <img className='section-8-velikikrug' src={Sekcija8velikikrug} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/PRi3iaXAm90?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
