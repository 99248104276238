import React from 'react';
import About from '../../components/About';
import Section3 from '../../components/Aleksandra';
import Section4 from '../../components/Eleonora';
import Section5 from '../../components/Jelena';
import Section6 from '../../components/Lena';
import Section7 from '../../components/Uros';
import Section8 from '../../components/Mihajlo';
import Header from '../../components/Header';


const HomePageTemplate = () => {
    return (
        <div>
            <Header />
            <About />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
            <Section7 />
            <Section8 />
            {/* <Section9 /> */}
            {/* <div id="sectionid">
                <Section10 />
            </div> */}
        </div>
     );
}

// u slucaju ako zatreba styled components
// const HomeWrapper = styled.section`
//     background:rgba(95,183,234,0.5);
//     .service-icon {
//         font-size: 2.5rem;
//         color:var(--primaryColor);
//     }
//     p {
//         color: var(--darkGrey);
//     }
// `


export default HomePageTemplate;