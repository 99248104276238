import React,{useState, useEffect} from 'react';
import Sectionbgwrap from '../assets/sectionbgwrap.png';
import Sekcija3bg from '../assets/sekcija3bg.png';
import Sekcija3cover from '../assets//sekcija3bgcover.png';
import Sekcija3figura1 from '../assets/sekcija3figura1.png';
import Sekcija3figura2 from '../assets/sekcija3figura2.png';
import Slika1 from '../assets/aleksandra.png';
import Sekcija3malikrug from '../assets/sekcija2malikrug.png';
import Sekcija3velikikrug from '../assets/sekcija2velikikrug.png';
import MenuClose from '../assets/menuclose.png';

export default function Aleksandra() {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])

  return (
    <div id="aleksandra" className='section3 position-relative' style={{backgroundImage:`url(${Sekcija3bg})`}}>
      <div className="d-flex flex-column flex-lg-row section-universal mx-auto align-items-center">
        <div  className="sectionslikaleft vibrate-1" onClick={()=> setOpen(true)}>
          <img src={Slika1} alt="" />
        </div>
        <div className="sectioncontent">
          <img className='section-wrapbg' src={Sectionbgwrap} alt="" />
          <img className='section-coverbg' src={Sekcija3cover} alt="" />
          <p className="section-text">
            <span className='color-green'>Александра Иконов</span> Кампстер платформа је место добрих онлајн курсева. Колико добрих, најбоље потврђује податак од двеста педесет хиљада полазника. Занима вас програмирање, маркетинг, цопywритинг, писање за wеб, фотографија и wеб дизајн... <a className='color-green'  href="/aleksandra">Прочитај више</a>
          </p>
          <img className='section3-figure1' src={Sekcija3figura2} alt="" />
          <img className='section3-figure2' src={Sekcija3figura1} alt="" />
          <img className='section-3-malikrug' src={Sekcija3malikrug} alt="" />
        </div>
      </div>
      <img className='section-3-velikikrug' src={Sekcija3velikikrug} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/PRi3iaXAm90?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}


{/* <iframe width="560" height="315" src="https://www.youtube.com/embed/yiEJ3osoJxs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

// https://www.youtube.com/embed/yiEJ3osoJxs?&autoplay=1