import React, {useContext} from 'react';
import Logo from '../assets/logo.png';
import MenuClose from '../assets/menuclose.png';
import MenuBg from '../assets/menu-bg.jpg';
import MenuAstronaut from '../assets/menu-astronaut.png';
import MenuShape from '../assets/menu-shape.png';
import MenuLine from '../assets/menu-line.png';
import {ProductContext} from '../context/context';
import Facebook from '../assets/facebook.png';
import Instagram from '../assets/instagram.png';
import Youtube from '../assets/youtube.png';



const Sidebar = (props) => {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;

  const unlockSidebar = () => {
    document.body.classList.remove("body-sidebar");
    handleSidebar(false);
  }

    return (
        <div style={{background: `url(${MenuBg})`}} className={`sidebar ${sidebarOpen === true ? 'sidebar-show' : '' } `}>
          <div className='d-flex justify-content-between'>
              <div className="logo">
                  <img src={Logo} alt="" />
              </div>
              <div onClick={() => handleSidebar()} style={{zIndex:2, cursor:'pointer'}} className="main-menu">
                <img src={MenuClose} alt="" />
              </div>
          </div>
          <div className='sidebar-line'>
            <img src={MenuLine} alt="" />
          </div>
          <div className='sidebar-astronaut'>
            <img src={MenuAstronaut} alt="" />
          </div>
          <div className='menu-shape'>
            <img src={MenuShape} alt="" />
          </div>
          <div className="sidebar-menu">
            <div>
              <a onClick={() => unlockSidebar()} className='text-uppercase' href="/#aleksandra">Кампстер</a>
            </div>
             <div>
              <a onClick={() => unlockSidebar()} className='text-uppercase' href="/#eleonora">МојоAI</a>
            </div>
             <div>
              <a onClick={() => unlockSidebar()} className='text-uppercase' href="/#jelena">HES</a>
            </div>
             <div>
              <a onClick={() => unlockSidebar()} className='text-uppercase' href="/#lena">Outpost Chess</a>
            </div>
             <div>
              <a onClick={() => unlockSidebar()} className='text-uppercase' href="/#uros">Беохост</a>
            </div>
             <div>
              <a  className='text-uppercase' href="/#nenad" onClick={() => unlockSidebar()}>Приче са душом</a>
            </div>
          </div>
          <div className="social-sidebar d-flex">
            <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
              <a href="https://www.facebook.com/InovacijeSvetOkoNas/" target="_blank"><img src={Facebook} alt="" /></a>
              <div className="line-social"></div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center mr-3 mr-md-5">
              <a href="https://www.instagram.com/inovacijesvetokonas/" target="_blank"><img src={Instagram} alt="" /></a>
              <div className="line-social"></div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center mr-3">
               <a href="https://www.youtube.com/channel/UC4abbBoDN5PSWjOrmGeLahw" target="_blank"><img src={Youtube} alt="" /></a>
              <div className="line-social"></div>
            </div>
          </div>
        </div>
     );
}




export default Sidebar;